import React, { useState } from 'react';
import { sphereoneSdk } from "../utils/SphereOne";
import { useOneTapProvider } from '../utils/Globals/CustomHooks';
import axios from 'axios';

const RegisterForm = ({ walletAddress, otidParam, removeForm, isSphereone, sphereOneId, sphereoneEmail }) => {
    const [username, setUsername] = useState('');
    const [email, setEmail] = useState('');
    const [referedCode, setReferedCode] = useState('');
    const [errorMessage, setErrorMessage] = useState('');
    const isMobile = /iPhone|iPad|iPod|Android/i.test(navigator.userAgent);

    const { setLoginMethod, setIsEthereumConnected, setIsWalletConnected, setFilteredWallets, setProfileInformation } = useOneTapProvider();

    const handleChangeUsername = (e) => {
        const value = e.target.value;
        if (!/\s/.test(value)) {
            setUsername(value);
        }
    };

    const handleChangeEmail = (e) => {
        const value = e.target.value;
        if (!/\s/.test(value)) {
            setEmail(value);
        }
    };

    const handleChangeReferedCode = (e) => {
        const value = e.target.value.trim();
        setReferedCode(value);
    };

    const handleSubmit = async (event) => {
        event.preventDefault();
        try {
            const userPayload = {
                username,
                walletId: walletAddress,
                email: isSphereone ? sphereoneEmail : email,
                referedCode,
                source: isSphereone ? "Sphereone" : "Ethereum",
                sphereOneId: isSphereone ? sphereOneId : undefined,
                otid: otidParam || undefined,
            };

            const response = await axios.post('https://api.qorbiworld.com/sphere-one/add-user', userPayload);

            if (!isSphereone) {
                const userResponse = await axios.get(`https://api.qorbiworld.com/sphere-one/user/passport/${walletAddress}`);
                const defaultProfilePicture = `https://ui-avatars.com/api/?background=random&length=1&name=${username}`;
                const updatedUserInfo = {
                    ...userResponse.data,
                    profilePicture: defaultProfilePicture,
                };

                setProfileInformation(updatedUserInfo);
                setLoginMethod('ETH');
                setIsEthereumConnected(true);
                setIsWalletConnected(true);
                setFilteredWallets([walletAddress]);
                localStorage.setItem('profileInformation', JSON.stringify(updatedUserInfo));
                localStorage.setItem('loginMethod', 'ETH');
                localStorage.setItem('isEthereumConnected', JSON.stringify(true));
                localStorage.setItem('isWalletConnected', JSON.stringify(true));
                localStorage.setItem('filteredWallets', JSON.stringify([walletAddress]));
            }

            removeForm();
        } catch (addUserError) {
            console.error('Error adding user:', addUserError);
            setErrorMessage(addUserError.response?.data?.message || 'An error occurred');
        }
    };

    const logoutSphereOne = async () => {
        localStorage.removeItem("STARK_PUBLIC_KEY");
        localStorage.removeItem("WALLET_ADDRESS");
        try {
            sphereoneSdk.logout();
        } catch (e) {
            console.error(e);
        }
        window.location.reload();
    };

    return (
        <div className="main-overlay">
            <div className='black-overlay' style={{ width: !isMobile && "30%" }}>
                <h2 style={{ color: "white", fontSize: 35 }}>Create Your Account</h2>
                {errorMessage && <div style={{ color: 'red', marginBottom: '10px' }}>{errorMessage}</div>}
                <form onSubmit={handleSubmit}>
                    <div className="register-form-group">
                        <label htmlFor="username" style={{ color: "white", display: "block", textAlign: "left", marginLeft: 10 }}>Username:</label>
                        <input
                            className='register-form-input'
                            type="text"
                            id="username"
                            placeholder="Ex: CoolQorUser"
                            value={username}
                            onChange={handleChangeUsername}
                            required
                        />
                    </div>

                    {!isSphereone &&
                        <div className="register-form-group">
                            <label htmlFor="email" style={{ color: "white", display: "block", textAlign: "left", marginLeft: 10 }}>Email:</label>
                            <input
                                className='register-form-input'
                                type="email"
                                id="email"
                                placeholder="Ex: cool@qor.user"
                                value={email}
                                onChange={handleChangeEmail}
                                required
                            />
                        </div>
                    }

                    <div className="register-form-group">
                        <label htmlFor="referedCode" style={{ color: "white", display: "block", textAlign: "left", marginLeft: 10 }}>Referral Code:</label>
                        <input
                            className='register-form-input'
                            type="text"
                            id="referedCode"
                            placeholder="Optional"
                            value={referedCode}
                            onChange={handleChangeReferedCode}
                        />
                    </div>

                    <button className='register-form-button' type="submit">Register</button>
                </form>
                <button
                    style={{ marginTop: 15, backgroundColor: "red", transition: "background-color 0.3s" }}
                    onMouseEnter={(e) => e.target.style.backgroundColor = "#B80000"}
                    onMouseLeave={(e) => e.target.style.backgroundColor = "red"}
                    className='register-form-button'
                    onClick={logoutSphereOne}>
                    Cancel Login
                </button>
            </div>
        </div>
    );
};

export default RegisterForm;
